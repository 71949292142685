@import "~antd/dist/antd.css";

th.MuiTableCell-root.MuiTableCell-head {
  background: #edffea;
  /* color: white; */
}

.ant-tabs-ink-bar {
  color: #429c46 !important;
  background-color: #429c46 !important;
}

.ant-btn-primary {
  background-color: #429c46 !important;
  border-color: #429c46 !important;
}
.ant-tabs-tab-active {
  color: #429c46 !important;
  border-radius: 5px !important;
  /* background-color: #429c46 !important; */
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #429c46 !important;
}

.ant-tabs-nav .ant-tabs-tab-active:hover {
  color: #fff !important;
}

.ant-tabs .ant-tabs-right-bar {
  border-left: 3px solid #429c46 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #429c46 !important;
}
